import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Divider, Slider } from '../components/main'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import music from '../assets/music/angga.aac'
import AOS from 'aos';
import post from '../params/post'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/img/logo.ico'
import '../style/jefri.css'
import path from '../assets/img/angga/path1.svg'
import path3 from '../assets/img/adnan/path3.svg'
import cincin from '../assets/img/jefri/cincin.svg'
import logoig from '../assets/img/jefri/logoig.svg'
import burung from '../assets/img/gold3/burung.svg'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { gambar } from '../params'
import { cap } from '../params'


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: ''
        }
    }
    useQuery = () => {
        return new URLSearchParams(this.props.location.search);

    }


    async componentDidMount() {


        try {




            AOS.init({
                // initialise with other settings
                duration: 1000
            });
            var countDownDate = new Date("11/15/2020").getTime();

            // Update the count down every 1 second
            var x = setInterval(() => {

                // Get today's date and time
                var now = new Date().getTime();

                // Find the distance between now and the count down date
                var distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                this.setState({
                    days: days, hours: hours, minutes: minutes, seconds: seconds
                })
            }, 1000);

        } catch (e) {
            console.log(e)
        }


    }
    play = () => {
        AOS.refresh()
        var snd = new Audio(music);
        snd.type = 'audio/aac';
        snd.play();

        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }
    googleCalendar = () => {
        window.open(`https://calendar.google.com/event?action=TEMPLATE&tmeid=MTJidW0wa29ja2wwcXNkN3Y3bmM1ZXJ1YmogYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com
      `, '_blank')
    }
    handleSubmit = async () => {
        let local = localStorage.getItem('pesan')
        console.log(this.nama.current.value, this.alamat.current.value, this.pesan.current.value)
        let err = []
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.alamat.current.value == "") {
            err.push('Alamat tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${this.nama.current.value}", hadir: "${this.state.hadir}", jumlahhadir: "", kepada: "angga-neni", pesan:"${this.pesan.current.value}",alamat: "${this.alamat.current.value}"`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            this.alamat.current.value = ''

                            }else{
                                err.push('Koneksi Gagal')
                            }

                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }



    }

    render() {
        let slide = ["	https://i.ibb.co/dp9mRht/IMG-20201101-WA0010.jpg	",
            "	https://i.ibb.co/r5mGf2x/IMG-20201102-WA0008.jpg	",
            "	https://i.ibb.co/7SYydPT/IMG-20201102-WA0009.jpg	",
            "	https://i.ibb.co/pRLH6xh/IMG-20201102-WA0011.jpg	",
            "	https://i.ibb.co/H2bwNGw/IMG-20201102-WA0012.jpg	",
            "	https://i.ibb.co/PDbvrXf/IMG-20201102-WA0013.jpg	",

        ]
        let slider = []
        slide.map(v => {
            slider.push(gambar(v, 95,
                'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=480'))
        })
        let query = this.useQuery().get('name');
        query = query ? cap(query) : ''
        let { days, hours, minutes, seconds, hide, hadir, err, submitted } = this.state
        console.log(submitted)
        return (
            <>
                <Helm
                    title='Undanganku - Angga & Neni'
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={'https://i.ibb.co/q7n0RRh/Modal-Depan-Dalam.jpg'}
                    url='https://undanganku.me/angga-neni'
                />
                <div id='jefri' style={{backgroundColor:'#DB7C4F'}}>
                    <Container fluid id='g3-header' className='relative'
                        style={{
                            backgroundImage: `url(${gambar('https://i.ibb.co/q7n0RRh/Modal-Depan-Dalam.jpg',
                                null, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1800&h=900')})`
                        }}>
                        <Item>
                            <Col xs={10} md={4} className='m-2 m-md-0 '>
                                <img className='img-fluid w-100 p-2'
                                    src={gambar('https://i.ibb.co/PYHv87V/Logo.png')} data-aos="fade-left" />
                            </Col>
                        </Item>
                        <Item>
                            {query ? (
                                <h2 className={`col-md-4 roboto-slab`}> Kepada : {query} </h2>
                            ) : false}</Item>
                        <Row className='justify-content-center'>
                            <div onClick={() => { this.play() }}
                                type='button' className={`col-md-4 button roboto-slab ${hide ? 'show' : 'hide'}`}>
                                Open Invitation
                            </div>
                        </Row>
                        <Col className={`absolute path ${hide ? 'hide' : 'show'}`} xs={12}>
                            <img className='img-fluid w-100' src={path} />
                        </Col>
                    </Container>
                    {!hide ? (
                        <>
                            <Container fluid>

                            </Container>
                            <Container fluid className='pt-3 pb-3' id='top' style={{backgroundColor:'#DB7C4F'}}>
                                <Item>
                                    <Col xs={4} md={2} className='p-3 p-md-5'>
                                        <img src={cincin} className='img-fluid w-100' data-aos="fade-left" />
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8}>
                                        <p className='italic roboto-slab c-white' data-aos="fade-right">
                                            بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم
                                    <br /><br />
                                    وَمِنْ ءَايَٰتِهِۦٓ أَنْ خَلَقَ لَكُم مِّنْ أَنفُسِكُمْ أَزْوَٰجًا لِّتَسْكُنُوٓا۟ إِلَيْهَا وَجَعَلَ بَيْنَكُم مَّوَدَّةً وَرَحْمَةً ۚ إِنَّ فِى ذَٰلِكَ لَءَايَٰتٍ لِّقَوْمٍ يَتَفَكَّرُونَ
 <br />
                                        </p>
                                        <p className='italic roboto-slab c-white f-hu' data-aos="fade-right">
                                        “Dan di antara tanda-tanda (kekuasaan) Nya adalah Dia menciptakan untuk kamu pasangan-pasangan (hidup) dari jenis kamu sendiri, supaya kamu tenang kepadanya, dan dijadikan-Nya di antara kamu mawaddah dan rahmat. Sesungguhnya pada yang demikian itu benar-benar terdapat bukti-bukti bagi kaum yang berpikir (tentang kuasa dan nikmat Allah swt).”
                                         <br /><br />(Ar-Rum: 21)
                            </p>
                                    </Col>
                                </Item>
                            </Container>
                            <Container id='pasangan' style={{backgroundColor:'white',borderRadius:'0.75rem'}} >

                                <Item>
                                    <div className='p-3'>
                                        <Item>
                                            <Col xs={12} md={10} className='p-3 rounded' data-aos="fade-left">
                                                <p className=' tinos' style={{fontSize:'16px'}}>
                                                    Maha Suci Allah yang telah menciptakan Makhluk-Nya secara Berpasang-pasangan.
                                    <br></br><br></br>
                                    Ya Allah Perkenankan kami merangkai kasih sayang yang kau ciptakan diantara Putra Putri Kami
                                    </p>
                                                <Item>
                                                    <h1 className='sacramento p-3'>
                                                        The Groom
                                                    </h1>
                                                </Item>

                                                {/* <Item>
                                                    <Col xs={6} md={3}>
                                                        <img src={jef1} className='rounded img-fluid w-100' />
                                                    </Col>
                                                </Item> */}


                                                <Item>
                                                    <h1 className='c-main sansita p-3'>
                                                        Angga Wijaya
                                                </h1>
                                                </Item>
                                                <Item>
                                                    <p className=' s-bold tinos'>Putra Dari :</p>
                                                </Item>
                                                <Item>
                                                    <p className=' tinos' style={{fontSize:'16px'}}>

                                                        Bpk. Dian Alamsyah <br />
                                            dan<br />
                                            Ibu Yuliawanti
                                        </p>
                                                </Item>
                                                <Item>
                                                    <Col xs={3} md={1}
                                                        type='button'
                                                        onClick={() => {
                                                            window.open('http://instagram.com/anggawijaya.aw91')
                                                        }}>
                                                        <img src={logoig} className='img-fluid w-100 p-1' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className='c-main lobster p-3 larger'>
                                                        &
                                    </h1>
                                                </Item>
                                                <Item>
                                                    <h1 className='sacramento p-3'>
                                                        The Bride
                                                    </h1>
                                                </Item>
                                                {/* <Item>
                                                    <Col xs={6} md={3}>
                                                        <img src={jef2} className='rounded img-fluid w-100' />
                                                    </Col>
                                                </Item> */}

                                                <Item>
                                                    <h1 className='c-main sansita p-3'>
                                                        Neni Nurhaeni
                                                </h1>
                                                </Item>
                                                <Item>
                                                    <p className='s-bold tinos'>Putri Dari :</p>
                                                </Item>
                                                <Item>
                                                    <p className='tinos' style={{fontSize:'16px'}}>

                                                        Bpk Kusnadi (alm) <br />
                                            dan<br />
                                            Ibu Ihat Solihat
                                        </p>
                                                </Item>
                                                <Item>
                                                    <Col xs={3} md={1} type='button'
                                                        onClick={() => {
                                                            window.open('http://instagram.com/_neninur')
                                                        }}>
                                                        <img
                                                            src={logoig} className='img-fluid w-100 p-1' data-aos="fade-left" />
                                                    </Col>
                                                </Item>

                                            </Col>
                                        </Item>
                                    </div>
                                </Item>
                            </Container>
                            <Container  className='bg-white p-4 mt-3' id='save' style={{borderRadius:'0.75rem'}}>
                                <h1 className='sacramento s-bold c-main' data-aos="fade-right">
                                    Save The Date
                                </h1>
                                <Item>
                                    <Col xs={10} md={6} className='p-3 rounded  s-bold c-main' data-aos="fade-left">
                                        <Item>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {days}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Days
                                        </span>
                                                </Item>
                                            </div>
                                            <div className='dot'>:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {hours}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Hours
                                    </span>
                                                </Item>
                                            </div>
                                            <div className='dot'>:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div >
                                                        {minutes}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Mins
                                        </span>
                                                </Item>
                                            </div>
                                            <div className='dot' >:</div>
                                            <div className='item'>
                                                <Item>
                                                    <div>
                                                        {seconds}
                                                    </div>
                                                </Item>
                                                <Item>
                                                    <span>
                                                        Secs
                                            </span>
                                                </Item>
                                            </div>
                                        </Item>
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8} className='p-3 border-pink rounded'>
                                        <Item>
                                            <Col xs={4} md={2} className='p-3'>
                                                <img src={burung} className='img-fluid w-100' />
                                            </Col>
                                        </Item>
                                        <h1 className='sacramento s-bold mt-3 c-main f-mid' data-aos="fade-left">
                                            Akad Nikah
                                </h1>
                                        <p className='f-small poppins mt-4' data-aos="fade-left">
                                            <div className='s-bold c-main'>
                                                Minggu, 15 November 2020
                                </div>
                                            08.30 WIB s/d selesai<br />
                                            <div className='s-bold c-main'>
                                                Dusun Mulyasari RT.010 RW.004 <br /></div>
                                                Desa Rancajaya Kecamatan Patokbeusi Kabupaten Subang - Jawa Barat. Ciasem, Kab. Subang, Jawa Barat, 41256 (Kediaman Ibu Ihat Solihat)

                                        </p>
                                        <Item>
                                            <Col onClick={() => { window.open('https://maps.app.goo.gl/fvjtw4omxKiyRpXJ8') }}
                                                xs={8} md={4} className='button poppins c-white f-small rounded p-1' type='button' data-aos="zoom-in">
                                                Get Direction
                            </Col>
                                        </Item>
                                        <Item>
                                            <Col onClick={() => this.googleCalendar()} xs={8} md={4} className='button poppins c-white f-small rounded p-1 mt-3' type='button' data-aos="zoom-in">
                                                Add to Google Calendar
                            </Col>

                                        </Item>
                                    </Col>
                                </Item>
                                <Item>
                                    <Col xs={12} md={8} 
                                    className='border-pink poppins c-grey rounded p-2 f-small' 
                                    data-aos="fade-right"
                                    style={{fontSize:'16px'}}>
                                        Tanpa Mengurangi Rasa Hormat semua tamu harap menerapkan Protokol Kesehatan dengan menggunakan masker ketika Acara dan menggunakan hand Sanitizer yang telah disediakan.
                            <br />
                            Terima Kasih
                            </Col>
                                </Item>
                            </Container >
                            <Container fluid id='slider' className='bg-pink p-3 pb-5'>
                                <Item><Col xs={12} md={8}>
                                    <Slider slide={slider} />
                                </Col>
                                </Item>


                            </Container>
                            <Container style={{backgroundColor:'#DB7C4F'}} fluid id='form'>
                                <Item>

                                    <Col xs={10} md={6} className='bg-white rounded poppins mt-3 pt-4 pb-4' data-aos="zoom-in">
                                        <h1 className='sacramento c-main f-small send'>
                                            Send Your Whises
                                        </h1>
                                        <form className="col-12 w-100 jefri">
                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Nama" name='nama' defaultValue={query ? query : ''} />
                                            <input ref={this.alamat} type='text' className="col-12 w-100 text-center" placeholder="Alamat" name='alamat' />
                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Pesan" name='pesan' />
                                            <Item>
                                                <div onClick={() => {
                                                    this.setState({ hadir: true })
                                                }
                                                }>
                                                    <input id="option" type="radio" checked={hadir ? true : false} />
                                                    <label ><span><span></span></span>Hadir</label>
                                                </div>
                                                <div onClick={() => {
                                                    this.setState({ hadir: false })
                                                }
                                                }>
                                                    <input id="option" type="radio" checked={hadir ? false : true} />
                                                    <label ><span><span></span></span>Tidak Hadir</label>
                                                </div>
                                            </Item>
                                            <Item>
                                                <Col xs={12} className=''>
                                                    {
                                                        submitted == true ? (
                                                            <Alert variant='success'>
                                                                Pesan anda sudah disampaikan
                                                            </Alert>) : (submitted === false ? (
                                                                <Alert variant='danger'>
                                                                    {
                                                                        err.map(val => {
                                                                            return (
                                                                                <li>{val}</li>
                                                                            )
                                                                        })
                                                                    }

                                                                </Alert>
                                                            ) : false)
                                                    }

                                                </Col>
                                            </Item>
                                            <Item>
                                                <button type='button' className='col-6 button rounded' onClick={() => this.handleSubmit()}> Kirim </button>
                                            </Item>
                                        </form>
                                    </Col>
                                </Item>
                                <Item>
                                    <h1 className='sacramento c-white f-small send pt-3 pb-3'>
                                        Angga & Neni
                                    </h1>
                                </Item>
                            </Container>
                            <Container fluid className='relative p-0' id='path' style={{backgroundColor:'#DB7C4F'}}>
                                <img className='absolute img-fluid w-100 mx' src={path3} />
                            </Container>
                            <Container fluid className='bg-white poppins pb-5' id='footer'>
                                <Item>
                                    <h1 data-aos="zoom-in" data-aos-duration="1000">
                                        Possible Wedding
              </h1>
                                </Item>
                                <Item>
                                    <h2 data-aos="zoom-in" data-aos-duration="1000">
                                        Digital Invitation
              </h2>
                                </Item>
                                <Item>
                                    <div className='col-3 col-lg-1 p-md-4'
                                        type='button'
                                        onClick={() => {
                                            window.open('http://instagram.com/possiblewedding')
                                        }}>
                                        <img src={logoig} className='img-fluid w-100 p-2 p-md-0'
                                        />
                                    </div>
                                </Item>
                            </Container>
                        </>
                    ) : false}
                </div>
            </>
        )
    }
}